// Generated by .maintenance/generators/routes.js
import AppLocaleDefault from "src/mycasino.ch/locales/de.json";

export default [
  {
    name: "maintenance-de",
    path: "/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "mycasino.ch",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "restricted-de",
    path: "/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "mycasino.ch - restricted",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "maintenance-fr",
    path: "/fr/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "mycasino.ch",
      localeLoader: () => import("../mycasino.ch/locales/fr.json")
    }
  },
  {
    name: "restricted-fr",
    path: "/fr/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "mycasino.ch - restricted",
      localeLoader: () => import("../mycasino.ch/locales/fr.json")
    }
  },
  {
    name: "maintenance-it",
    path: "/it/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "mycasino.ch",
      localeLoader: () => import("../mycasino.ch/locales/it.json")
    }
  },
  {
    name: "restricted-it",
    path: "/it/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "mycasino.ch - restricted",
      localeLoader: () => import("../mycasino.ch/locales/it.json")
    }
  },
  {
    name: "maintenance-en",
    path: "/en/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "mycasino.ch",
      localeLoader: () => import("../mycasino.ch/locales/en.json")
    }
  },
  {
    name: "restricted-en",
    path: "/en/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "mycasino.ch - restricted",
      localeLoader: () => import("../mycasino.ch/locales/en.json")
    }
  },
  {
    path: "*",
    component: () => import("src/.maintenance/views/404.vue"),
    meta: {
      title: "mycasino.ch - 404"
    }
  }
];
